import Axios from 'axios';
import { get } from 'lodash';
import moment from 'moment';
import apis from './configs/apis';

const dateFormat = 'D.M.Y';
export const datepickerFormat = `${dateFormat} HH:mm`;
export const timeFormat = `${dateFormat} HH:mm`;
export const formatDate = (v: Date | string) => moment(v).format(dateFormat);
export const formatTime = (v: Date | string) => moment(v).format(timeFormat);
export const formatFrom = (v: Date | string) => moment(v).fromNow();
export const formatDateAndFrom = (v: Date | string) =>
  `${moment(v).format(dateFormat)} (${formatFrom(v)})`;
export const formatTimeAndFrom = (v: Date | string) =>
  `${moment(v).format(timeFormat)} (${formatFrom(v)})`;

export const uploadFile = async (file: any, Key: string) => {
  // Request signed URL from server
  const { data: signedUrl } = await Axios.post(apis.S3.UPLOAD_SIGN_URL, {
    fileName: Key,
  });

  const ax = Axios.create();

  delete ax.defaults.headers.common.Authorization;

  try {
    await ax.put(signedUrl, file);
    const url = signedUrl.split('?')[0];
    return url;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('e', e);

    // eslint-disable-next-line no-console
    console.log('e.response', JSON.stringify(get(e, 'response'), null, 2));
    throw new Error('Failed to upload file');
  }
};

export const fixFloat = (x: number) => Math.round(x * 100) / 100;

export const addressRegex = /^[a-zA-ZäöåÄÖÅ 0-9-]+$/;
export const nameRegex = /^[a-zA-ZäöåàèìòùáéíóúýÄÖÅÀÈÌÒÙÁÉÍÓÚÝ -]+$/;
export const cityRegex = /^[a-zA-ZäöåÄÖÅ -]+$/;
export const zipRegex = /^[a-zA-ZäöåÄÖÅ0-9 ]+$/;
export const IBANRegex = /^[a-zA-Z0-9]+$/;
