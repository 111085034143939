import React from 'react';
import { Card, CardTitle, CheckBox } from 'releox-react';

export default () => {
  return (
    <Card>
      <CardTitle>Details</CardTitle>
      <CheckBox name="termsAccepted" label="Terms and conditions accepted" />
      <CheckBox name="offerAccepted" label="Offer accepted" />
      <CheckBox name="insuranceReturn" label="Insurance return" />
      <CheckBox name="ignobleReturn" label="Ignoble return" />
      <CheckBox name="offeredDeclined" label="Offer declined" />
      <CheckBox name="isDonation" label="Donation" />
      <CheckBox name="sendTrustPilot" label="Send to trust pilot" />
    </Card>
  );
};
