import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardTitle, Details } from 'releox-react';
import { Country } from '../../classes/Country';
import TellersAction from '../../store/teller/TellersAction';
import TellersSelector from '../../store/teller/TellersSelector';
import sortProducts from '../../utils/sort-products';

export default () => {
  const dispatch = useDispatch();
  const formValues = useSelector(TellersSelector.selectFormValues);
  const offer = useSelector(TellersSelector.selectOffer);
  const sekOffer = useSelector(TellersSelector.selectsekOffer);
  const gbpOffer = useSelector(TellersSelector.selectgbpOffer);

  const backToTellerView = useCallback(() => {
    dispatch(TellersAction.showPreview(false));
  }, [dispatch]);

  return (
    <div className="row">
      <div className="col-sm-6 offset-sm-3">
        <Card>
          <CardTitle>Preview</CardTitle>
          <div className="row">
            <div className="col-sm-6">
              <Details
                object={formValues.client}
                properties={[
                  {
                    text: 'First Name',
                    dataField: 'firstName',
                  },
                  {
                    text: 'Last Name',
                    dataField: 'lastName',
                  },
                  {
                    text: 'Address',
                    dataField: 'address',
                  },
                  {
                    text: 'Phone',
                    dataField: 'phone',
                  },
                  {
                    text: 'Birth year',
                    dataField: 'birthYear',
                  },
                  {
                    text: 'City',
                    dataField: 'city',
                  },
                  {
                    text: 'Zip',
                    dataField: 'zip',
                  },
                  {
                    text: 'Email',
                    dataField: 'email',
                  },
                ]}
              />
            </div>
            <div className="col-sm-6">
              <ul>
                {formValues.termsAccepted ? <li>Terms and conditions accepted</li> : ''}
                {formValues.offerAccepted ? <li>Offer accepted</li> : ''}
                {formValues.offeredDeclined ? <li>Offer declined</li> : ''}
                {formValues.ignobleReturn ? <li>Ignoble return</li> : ''}
                {formValues.insuranceReturn ? <li>Insurance Return</li> : ''}
                {formValues.isDonation ? <li>Donation</li> : ''}
                {formValues.sendTrustPilot ? <li>Send Trust Pilot Email</li> : ''}
              </ul>
            </div>
          </div>
          <hr />
          <CardTitle>In deal products</CardTitle>
          <ul>
            {formValues.products
              ?.filter((p) => p.inDeal)
              .sort(sortProducts)
              .map((p) => (
                <li key={p.key}>
                  {p.name
                    ? `${p.densityName} (${p.name}) ${p.price} €`
                    : `${p.densityName} ${p.price} €`}
                </li>
              ))}
          </ul>
          <CardTitle>
            <>
              <p>
                The customer will be paid: <b>{offer} euros</b>
              </p>
              {formValues.country === Country.UK && (
                <p>
                  {/* IF uk show gbpoffer */}
                  (GBP: {gbpOffer})
                </p>
              )}
              {formValues.country === Country.SWEDEN && (
                <p>
                  {/* IF uk show gbpoffer */}
                  (SEK: {sekOffer})
                </p>
              )}
            </>
          </CardTitle>
          <Button id="back-button" onClick={backToTellerView}>
            Back
          </Button>
          <Button type="submit" className="float-right" id="submit-button">
            Approve
          </Button>
        </Card>
      </div>
    </div>
  );
};
